import Amplify,{API, Auth} from 'aws-amplify';

Amplify.configure({
    API: {
      endpoints: [
        {
          name: 'prod',
          endpoint:'https://api.wishatl.com/operation/auth/',
        },
        {
          name: 'dev',
          endpoint:'https://api.wishatl.com/staging/auth/',
        },      ],
    },
  });
  
let rowList = [];

export async function Post(event, type, args) {

  const _url = window.location.href;
  let endpoint_env = 'prod';

  if(_url.match('staging') || _url.match('localhost')){
    endpoint_env = 'dev';
  };

  let i =0;
  let reqPart = {
    headers: { 
      Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
    },
    body: {
      "function":type,
      "event":event,
      ...args
    },
  };

  console.log('Request : ');
  console.log(reqPart);

  rowList = await API.post(endpoint_env, type, reqPart);

  if(type === 'reports'){
    for(var [key, value] of Object.entries(rowList)){
        value['id']=key+i++;
    }
  }

  return rowList;    
};
