import * as React from "react";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import Box from "@mui/material/Box";

import RaffleCreate from "./RaffleCreate";
import RaffleManage from "./RaffleManage";

const options = {
  create: "Create Raffle",
  manage: "Manage Raffle",
  prev: "dummy",
};

export default function RaffleSelect(user) {
  const [alignment, setAlignment] = React.useState("create");

  const handleChange = (event, newAlignment) => {
    newAlignment === null
      ? (newAlignment = options["prev"])
      : setAlignment(newAlignment);
    options["prev"] = newAlignment;
  };

  return (
    <div>
      <Box
        component="div"
        sx={{
          whiteSpace: "nowrap",
          my: 2,
          bgcolor: "background.paper",
        }}
      ></Box>
      <ToggleButtonGroup
        color="primary"
        value={alignment}
        exclusive
        onChange={handleChange}
      >
        <ToggleButton value="create">{options["create"]}</ToggleButton>
        <ToggleButton value="manage">{options["manage"]}</ToggleButton>
      </ToggleButtonGroup>

      <div>{content(alignment, user)}</div>
    </div>
  );
}

const content = (alignment, auth) => {
  switch (alignment) {
    case "create":
      return <RaffleCreate user={auth} />;

    case "manage":
      return <RaffleManage user={auth} />;

    default:
      break;
  }
};
