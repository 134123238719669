import * as React from 'react';
import ReportsSelect from './ReportsSelect';
import RaffleSelect from './RaffleSelect';

function Contents({func, user}){
    return content({func, user});
}

const content = ({func, user}) =>{
    switch(func){
    case 0:
        return <ReportsSelect />;
    case 1:
        return <RaffleSelect />;
    default:
        return <h1>Hello, {user.attributes.nickname}</h1>;
    };
};


export default Contents;

