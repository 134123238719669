import React from 'react';
import './App.css';
import Amplify from 'aws-amplify';
import { AmplifyAuthenticator, AmplifySignIn } from '@aws-amplify/ui-react'; 
import { AuthState, onAuthUIStateChange } from '@aws-amplify/ui-components';
import awsconfig from './aws-exports';

import Header from './AppBar';
import Contents from './Contents';

Amplify.configure(awsconfig);

export const FunctionContext = React.createContext();
export const UserContext = React.createContext();

const initialState = {
  type: -1,
  prev: -1
};

const reducer = (state, action) => {
  
  switch(action.type){
    case null:
      return { ...state, type: state.prev ,prev: state.prev};
    case 0:
      return { ...state, type: 0 ,prev: state.type};
    case 1:
      return { ...state, type: 1 ,prev: state.type};
    default:
      return { ...state, type: state.prev ,prev: state.prev};
  }
};

const App = () =>{
  
  const [authState, setAuthState] = React.useState();
  const [user, setUser] = React.useState();
  React.useEffect(() => {
    return onAuthUIStateChange((nextAuthState, authData) => {
        setAuthState(nextAuthState);
        setUser(authData)
    });
  }, []);

  const [func, dispatch] = React.useReducer(reducer, initialState);

  return authState === AuthState.SignedIn && user ? (
    <div className="App">
      <header>
      <FunctionContext.Provider value={{func, functionDispatch: dispatch}}>
      <Header user={user}/>
      </FunctionContext.Provider>
      </header>
      <body>
      <UserContext.Provider value={user}>
        <Contents func={func.type} user={user} />
      </UserContext.Provider>
      </body>

    </div>
    ) : (
    <AmplifyAuthenticator usernameAlias="email">
     <AmplifySignIn slot="sign-in" hideSignUp={true} />
    </AmplifyAuthenticator>
  );
}
export default App;
