import * as React from 'react';
import {Menu, MenuItem, ListItem, ListItemText , ListItemIcon, } from '@mui/material';

import DescriptionIcon from '@mui/icons-material/Description';

import GetReports from './GetReports'
import GetReportsWithFields from './GetReportsWithFields'
import GetReportsWithDateselect from './GetReportsWithDateselect'

const options = {
    checkpobrand:'PO by Brand',
    checkpocategory:'PO by Category',
    checkpocategorybrand:'PO by Category/Brand',
    checkage:'Age',
    checkagenike:'Age Nike',
    checkagejordan:'Age Jordan',
    checksalesanalysis:'SalesAnalysis',
    checkysmw:'YTD | STD | MTD | WTD',
};

const ITEM_HEIGHT = 48;


export default function ReportsSelect() {

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [selectedType, setSelectedType] = React.useState('dummy');
    const open = Boolean(anchorEl);
    const handleClick = (event, index) => {
        setSelectedType(null);
        setAnchorEl(event.currentTarget);
    };
    const handleClose = (event, key) => {
      setSelectedType(key);
      setAnchorEl(null);
    };

  

    return (
        <div>
            <ListItem
            aria-label="more"
            id="long-button"
            aria-controls="long-menu"
            aria-expanded={open ? 'true' : undefined}
            aria-haspopup="true"
            onClick={handleClick}
            >
                   <ListItemIcon>
          <DescriptionIcon />
        </ListItemIcon>
                <ListItemText
                primary={options[selectedType] ? options[selectedType] : 'Select reports type'}
               />
            </ListItem>
            <Menu
                id="long-menu"
                MenuListProps={{
                'aria-labelledby': 'long-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={(event) => handleClose(null)}
                PaperProps={{
                style: {
                    maxHeight: ITEM_HEIGHT * 4.5,
                    width: '20ch',
                },
                }}
            >
                {Object.keys(options).map((key, index) => (
                <MenuItem key={key} selected={index === 'dummy'} onClick={(event) => handleClose(event, key)}>
                    {options[key]}
                </MenuItem>
                ))}
            </Menu>
            <div>
            <h1>{options[selectedType]}</h1>
            {content(selectedType)}
            </div>

        </div>
    )
  
}  

const content = (selectedType) =>{

    if(options[selectedType]){
        if(selectedType === 'checkysmw'){
            return <GetReportsWithFields report={selectedType} />
        }else if(selectedType === 'checksalesanalysis'){
            return <GetReportsWithDateselect report={selectedType} />
        }else{
            return <GetReports report={selectedType} />
        }
    }else{
        return <div></div>;
    } ;
};
