import React from 'react';
import {Box, AppBar, Toolbar, Typography} from '@mui/material';
import {Menu, MenuItem, IconButton} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';

import { AmplifySignOut } from '@aws-amplify/ui-react'; 
import { FunctionContext } from './App'; 

// const options = [
//   'Report',
//   'Raffle',
// ];
const options = [
  'Report',
];

const ITEM_HEIGHT = 48;

export default function ButtonAppBar(args) {

  const functionContext = React.useContext(FunctionContext);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (index) => {
    functionContext.functionDispatch({type: index})

    setAnchorEl(null);
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" >
        <Toolbar>
          <IconButton
          id="long-button"
          aria-label="menu"
          size="large"
          aria-controls="long-menu"
          aria-expanded={open ? 'true' : undefined}
          color="inherit"
          edge="start"
          aria-haspopup="true"
          sx={{ mr: 2 }}
          onClick={handleClick}
          >
            <MenuIcon />
          </IconButton>
          <Menu
            id="long-menu"
            MenuListProps={{
              'aria-labelledby': 'long-button',
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={(event) => handleClose(null)}
            PaperProps={{
              style: {
                maxHeight: ITEM_HEIGHT * 4.5,
                width: '20ch',
              },
            }}
          >
            {options.map((option, index) => (
              <MenuItem key={option} selected={index === -1 } onClick={(event) => handleClose(index)}>
                {option}
              </MenuItem>
            ))}
          </Menu>
          <Typography variant="h4" sx={{ flexGrow: 1 }}>
          <b>wish Back Office</b>
          </Typography>
          <AmplifySignOut/>
        </Toolbar>
      </AppBar>
    </Box>
  );
}

