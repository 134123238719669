import React from 'react';
import { DataGrid,
    GridToolbarContainer,
    GridToolbarExport,
    GridToolbarFilterButton,
} from '@mui/x-data-grid';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import {Post} from './restPost';

function CustomToolbar(type) {

    return (
      <GridToolbarContainer>
        <GridToolbarExport />
        <GridToolbarFilterButton />
      </GridToolbarContainer>
    );
  }

export default function GetReports(args) {

  const [rowList, setRowList] = React.useState([]);
  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    let unmounted = false;
    (async()=>{
      try{
        setOpen(!open);
        const result = await Post(args.report, 'reports'); 
        if(!unmounted){
          setRowList(result);
        };
      }catch(err){
          alert("Can not find the product.\n"+err);
      }finally{
          setOpen(false);
      }
    })();

    return ()=>{ unmounted = true;};
  },[]);

  return (
      <div>
          <div style={{ height: 800, width: '100%' }}>
              <DataGrid 
                  disableSelectionOnClick
                  rows={rowList} 
                  density="compact"
                  components={{Toolbar: CustomToolbar}}
                  columns={getColumns(args['report'])}
                  pageSize={100}
                  />
          </div>
          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={open}
            >
            <CircularProgress color="inherit" />
          </Backdrop>
      </div>   
  );
}

function getColumns(type){

  let column = [];

  switch(type){
    case 'checkpobrand':
      column = [
        { field: 'Name_exp_1', headerName: ' ', width: 200, sortable: false},
        { field: 'Past Due', headerName: ' ', width: 100 ,type: 'number', sortable: false},
        { field: 'This Month', headerName: ' ', width: 100 ,type: 'number', sortable: false},
        { field: '+1M', headerName: ' ', width: 100 ,type: 'number', sortable: false},
        { field: '+2M', headerName: ' ', width: 100 ,type: 'number', sortable: false},
        { field: '+3M', headerName: ' ', width: 100 ,type: 'number', sortable: false},
        { field: '+4M', headerName: ' ', width: 100 ,type: 'number', sortable: false},
        { field: '+5M', headerName: ' ', width: 100 ,type: 'number', sortable: false},
        { field: '+6M', headerName: ' ', width: 100 ,type: 'number', sortable: false},
        { field: '+7M', headerName: ' ', width: 100 ,type: 'number', sortable: false},
        { field: '+8M', headerName: ' ', width: 100 ,type: 'number', sortable: false},
        { field: '+9M', headerName: ' ', width: 100 ,type: 'number', sortable: false},
        { field: '+10M', headerName: ' ', width: 100 ,type: 'number', sortable: false},
        { field: '+11M', headerName: ' ', width: 100 ,type: 'number', sortable: false},
        { field: 'Next Year', headerName: ' ', width: 100 ,type: 'number', sortable: false},
        { field: 'Total', headerName: ' ', width: 100 ,type: 'number', sortable: false},
      ];
    break;

    case 'checkpocategory':
      column = [
        { field: 'Name_exp_1', headerName: ' ', width: 200, sortable: false},
        { field: 'Name_exp_2', headerName: ' ', width: 200, sortable: false},
        { field: 'Past Due', headerName: ' ', width: 100 ,type: 'number', sortable: false},
        { field: 'This Month', headerName: ' ', width: 100 ,type: 'number', sortable: false},
        { field: '+1M', headerName: ' ', width: 100 ,type: 'number', sortable: false},
        { field: '+2M', headerName: ' ', width: 100 ,type: 'number', sortable: false},
        { field: '+3M', headerName: ' ', width: 100 ,type: 'number', sortable: false},
        { field: '+4M', headerName: ' ', width: 100 ,type: 'number', sortable: false},
        { field: '+5M', headerName: ' ', width: 100 ,type: 'number', sortable: false},
        { field: '+6M', headerName: ' ', width: 100 ,type: 'number', sortable: false},
        { field: '+7M', headerName: ' ', width: 100 ,type: 'number', sortable: false},
        { field: '+8M', headerName: ' ', width: 100 ,type: 'number', sortable: false},
        { field: '+9M', headerName: ' ', width: 100 ,type: 'number', sortable: false},
        { field: '+10M', headerName: ' ', width: 100 ,type: 'number', sortable: false},
        { field: '+11M', headerName: ' ', width: 100 ,type: 'number', sortable: false},
        { field: 'Next Year', headerName: ' ', width: 100 ,type: 'number', sortable: false},
        { field: 'Total', headerName: ' ', width: 100 ,type: 'number', sortable: false},
      ];
    break;

    case 'checkpocategorybrand':
      column = [
        { field: 'Name_exp_1', headerName: ' ', width: 200, sortable: false},
        { field: 'Name_exp_2', headerName: ' ', width: 200, sortable: false},
        { field: 'Name_exp_3', headerName: ' ', width: 200, sortable: false},
        { field: 'Past Due', headerName: ' ', width: 100 ,type: 'number', sortable: false},
        { field: 'This Month', headerName: ' ', width: 100 ,type: 'number', sortable: false},
        { field: '+1M', headerName: ' ', width: 100 ,type: 'number', sortable: false},
        { field: '+2M', headerName: ' ', width: 100 ,type: 'number', sortable: false},
        { field: '+3M', headerName: ' ', width: 100 ,type: 'number', sortable: false},
        { field: '+4M', headerName: ' ', width: 100 ,type: 'number', sortable: false},
        { field: '+5M', headerName: ' ', width: 100 ,type: 'number', sortable: false},
        { field: '+6M', headerName: ' ', width: 100 ,type: 'number', sortable: false},
        { field: '+7M', headerName: ' ', width: 100 ,type: 'number', sortable: false},
        { field: '+8M', headerName: ' ', width: 100 ,type: 'number', sortable: false},
        { field: '+9M', headerName: ' ', width: 100 ,type: 'number', sortable: false},
        { field: '+10M', headerName: ' ', width: 100 ,type: 'number', sortable: false},
        { field: '+11M', headerName: ' ', width: 100 ,type: 'number', sortable: false},
        { field: 'Next Year', headerName: ' ', width: 100 ,type: 'number', sortable: false},
        { field: 'Total', headerName: ' ', width: 100 ,type: 'number', sortable: false},
      ];
    break;

    case 'checkage':
      column = [
        { field: 'CATEGORY',  width: 200, sortable: false},
        { field: 'VENDOR',  width: 150, sortable: false},
        { field: 'SKU',  width: 150, sortable: false},
        { field: 'TITLE',  width: 200, sortable: false},
        { field: 'COLOR',  width: 200, sortable: false},
        { field: 'QTY',  width: 150 ,type: 'number', sortable: false},
        { field: 'RETAIL',  width: 150 ,type: 'number', sortable: false},
        { field: 'VALUE BY RETAIL',  width: 150 ,type: 'number', sortable: false},
        { field: '%Store BY RETAIL',  width: 150 ,type: 'number', sortable: false},
        { field: 'COST',  width: 150 ,type: 'number', sortable: false},
        { field: 'VALUE BY COST',  width: 150 ,type: 'number', sortable: false},
        { field: '%Store BY COST',  width: 150 ,type: 'number', sortable: false},
        { field: 'AGE',  width: 100 ,type: 'number', sortable: false},
      ];
    break;

    case 'checksalesanalysis':
      column = [
        { field: 'CATEGORY',  width: 200, sortable: false},
        { field: 'VENDOR',  width: 150, sortable: false},
        { field: 'SKU',  width: 150, sortable: false},
        { field: 'TITLE',  width: 200, sortable: false},
        { field: 'COLOR',  width: 200, sortable: false},
        { field: 'QTY',  width: 150 ,type: 'number', sortable: false},
        { field: 'RETAIL',  width: 150 ,type: 'number', sortable: false},
        { field: 'VALUE BY RETAIL',  width: 150 ,type: 'number', sortable: false},
        { field: '%Store BY RETAIL',  width: 150 ,type: 'number', sortable: false},
        { field: 'COST',  width: 150 ,type: 'number', sortable: false},
        { field: 'VALUE BY COST',  width: 150 ,type: 'number', sortable: false},
        { field: '%Store BY COST',  width: 150 ,type: 'number', sortable: false},
        { field: 'AGE',  width: 100 ,type: 'number', sortable: false},
        { field: 'SELL_QTY',  width: 150 ,type: 'number', sortable: false},
        { field: 'SALES',  width: 150 ,type: 'number', sortable: false},
        { field: 'MKDOWN%',  width: 150 ,type: 'number', sortable: false},
        { field: 'PROFIT',  width: 150 ,type: 'number', sortable: false},
        { field: 'GP%',  width: 150 ,type: 'number', sortable: false},
        { field: 'SELL THRU% QTY',  width: 150 ,type: 'number', sortable: false},
      ];
    break;

    default:

  }

  return column;

};