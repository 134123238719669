import React from 'react';
import { DataGrid,
    GridToolbarContainer,
    GridToolbarExport,
    GridToolbarFilterButton,
} from '@mui/x-data-grid';
import Button from "@mui/material/Button";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';


import {Post} from './restPost';

function CustomToolbar(type) {

    return (
      <GridToolbarContainer>
        <GridToolbarExport />
        <GridToolbarFilterButton />
      </GridToolbarContainer>
    );
  }

export default function GetReports(args) {

  const [rowList, setRowList] = React.useState([]);
  const [open, setOpen] = React.useState(false);

  async function getReports(type) {
    try{
      setOpen(!open);
      setRowList(await Post(type, 'reports'));
    }catch(err){
        alert("Can not find the product.\n"+err);
    }finally{
        setOpen(false);
    }
  }

    return (
        <div>
          <div>
            <p>Whole data (It takes 20-25sec to show after button clicking)</p>
            <Button variant="contained"
                        color="primary"
                        onClick = {()=> {getReports("checksalesytd")}}>
                            YTD
            </Button>
            <Button variant="contained"
                        color="primary"
                        onClick = {()=> {getReports("checksalesstd")}}>
                            STD
            </Button>
            <Button variant="contained"
                        color="primary"
                        onClick = {()=> {getReports("checksalesmtd")}}>
                            MTD
            </Button>
            <Button variant="contained"
                        color="primary"
                        onClick = {()=> {getReports("checksaleswtd")}}>
                            WTD
            </Button>
            </div>
            <br></br>
            <div>
            <p>By Brand (It takes 5-10sec to show after button clicking)</p>
            <Button variant="contained"
                        color="primary"
                        onClick = {()=> {getReports("checksalesytd_s")}}>
                            YTD
            </Button>
            <Button variant="contained"
                        color="primary"
                        onClick = {()=> {getReports("checksalesstd_s")}}>
                            STD
            </Button>
            <Button variant="contained"
                        color="primary"
                        onClick = {()=> {getReports("checksalesmtd_s")}}>
                            MTD
            </Button>
            <Button variant="contained"
                        color="primary"
                        onClick = {()=> {getReports("checksaleswtd_s")}}>
                            WTD
            </Button>
          </div>
          <div style={{ height: 800, width: '100%' }}>
              <DataGrid 
                  disableSelectionOnClick
                  rows={rowList} 
                  density="compact"
                  components={{Toolbar: CustomToolbar}}
                  columns={columns}
                  pageSize={100}
                  />
          </div>
          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={open}
            >
            <CircularProgress color="inherit" />
          </Backdrop>
        </div>   
    );
}

const columns = [
  { field: 'CATEGORY',  width: 200, sortable: false},
  { field: 'VENDOR',  width: 150, sortable: false},
  { field: 'SKU',  width: 150, sortable: false},
  { field: 'TITLE',  width: 200, sortable: false},
  { field: 'COLOR',  width: 200, sortable: false},
  { field: 'QTY',  width: 150 ,type: 'number', sortable: false},
  { field: 'RETAIL',  width: 150 ,type: 'number', sortable: false},
  { field: 'VALUE BY RETAIL',  width: 150 ,type: 'number', sortable: false},
  { field: '%Store BY RETAIL',  width: 150 ,type: 'number', sortable: false},
  { field: 'COST',  width: 150 ,type: 'number', sortable: false},
  { field: 'VALUE BY COST',  width: 150 ,type: 'number', sortable: false},
  { field: '%Store BY COST',  width: 150 ,type: 'number', sortable: false},
  { field: 'AGE',  width: 100 ,type: 'number', sortable: false},
  { field: 'SELL_QTY',  width: 150 ,type: 'number', sortable: false},
  { field: 'SALES',  width: 150 ,type: 'number', sortable: false},
  { field: 'MKDOWN%',  width: 150 ,type: 'number', sortable: false},
  { field: 'PROFIT',  width: 150 ,type: 'number', sortable: false},
  { field: 'GP%',  width: 150 ,type: 'number', sortable: false},
  { field: 'SELL THRU% QTY',  width: 150 ,type: 'number', sortable: false},
  { field: '_SELL_QTY',  width: 150 ,type: 'number', sortable: false},
  { field: '_SALES',  width: 150 ,type: 'number', sortable: false},
  { field: '_MKDOWN%',  width: 150 ,type: 'number', sortable: false},
  { field: '_PROFIT',  width: 150 ,type: 'number', sortable: false},
  { field: '_GP%',  width: 150 ,type: 'number', sortable: false},
  { field: '_SELL THRU% QTY',  width: 150 ,type: 'number', sortable: false},
];
