/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_appsync_graphqlEndpoint": "https://xvyiasntt5bslirheltyr6thdm.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-yct3yvp55vctrjkgoleqox5pfi",
    "aws_cognito_identity_pool_id": "us-east-1:968d5f3f-a42c-43b0-a24d-31e76c15e73d",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_O5cugy0j7",
    "aws_user_pools_web_client_id": "5i62a7ps571f5fpfc6lkgock3",
    "oauth": {},
    "aws_cognito_login_mechanisms": [],
    "aws_cognito_signup_attributes": [
        "EMAIL",
        "NICKNAME"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_NUMBERS",
            "REQUIRES_SYMBOLS",
            "REQUIRES_UPPERCASE"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ]
};


export default awsmobile;
