import * as React from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import SplitPane from "react-split-pane";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import { createTheme } from "@mui/material/styles";
import { createStyles, makeStyles } from "@mui/styles";

import { Post } from "./restPost";

function productToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}

function entryToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarExport />
      <GridToolbarFilterButton />
    </GridToolbarContainer>
  );
}

const defaultTheme = createTheme();
const useStyles = makeStyles(
  (theme) =>
    createStyles({
      root: {
        border: 0,
        color:
          theme.palette.mode === "light"
            ? "rgba(0,0,0,.85)"
            : "rgba(255,255,255,0.85)",
        fontFamily: [
          "-apple-system",
          "BlinkMacSystemFont",
          '"Segoe UI"',
          "Roboto",
          '"Helvetica Neue"',
          "Arial",
          "sans-serif",
          '"Apple Color Emoji"',
          '"Segoe UI Emoji"',
          '"Segoe UI Symbol"',
        ].join(","),
        WebkitFontSmoothing: "auto",
        letterSpacing: "normal",
        "& .MuiDataGrid-columnsContainer": {
          backgroundColor:
            theme.palette.mode === "light" ? "#539ee9" : "#1d1d1d",
        },
        "& .MuiDataGrid-iconSeparator": {
          display: "none",
        },
        "& .MuiDataGrid-columnHeader, .MuiDataGrid-cell": {
          borderTop: `1px solid ${
            theme.palette.mode === "light" ? "#f0f0f0" : "#303030"
          }`,
          borderRight: `1px solid ${
            theme.palette.mode === "light" ? "#f0f0f0" : "#303030"
          }`,
        },
        "& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell": {
          borderBottom: `1px solid ${
            theme.palette.mode === "light" ? "#f0f0f0" : "#303030"
          }`,
        },
        "& .MuiDataGrid-cell": {
          color:
            theme.palette.mode === "light"
              ? "rgba(0,0,0,.85)"
              : "rgba(255,255,255,0.65)",
        },
        "& .MuiPaginationItem-root": {
          borderRadius: 0,
        },
      },
    }),
  { defaultTheme }
);

const divStyle = {
  height: "650px",
};

export default function RaffleManage() {
  const classes = useStyles();
  const [options, setOptions] = React.useState([]);
  const [entryStatus, setEntryStatus] = React.useState(false);
  const [entries, setEntries] = React.useState([]);
  const [winners, setWinners] = React.useState(false);
  const [productId, setProductId] = React.useState("");
  const [value, setValue] = React.useState("");
  const [inputValue, setInputValue] = React.useState("");
  const [status, setStatus] = React.useState("");
  const [title, setTitle] = React.useState("");
  const [sku, setSku] = React.useState("");
  const [vendor, setVendor] = React.useState("");
  const [src, setSrc] = React.useState("");
  const [rowList, setRowList] = React.useState([]);
  const [invoice, setInvoice] = React.useState(true);
  const [gaonly, setGaonly] = React.useState(true);
  const [startDate, setStartDate] = React.useState(null);
  const [endDate, setEndDate] = React.useState(null);
  const [deadline, setDeadline] = React.useState(null);
  const [cancel, setCancel] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [select, setSelection] = React.useState([]);
  const [label, setLabel] = React.useState("UPDATE RAFFLE");

  React.useEffect(() => {
    try {
      (async () => {
        const result = await Post("getAllRaffle", "release");
        let option = [];
        for (var [key, value] of Object.entries(result)) {
          option.push({
            label: [value["SKU"], value["TITLE"], value["VENDOR"]].join(" / "),
            value: value["UUID"],
          });
        }
        setOptions(option);
      })();
    } catch (err) {
      alert("Can not find the product.\n" + err);
    } finally {
    }
  }, []);

  const handleChangeInvoice = (event) => {
    setButtonLabel(status, event.target.checked, setLabel);
    setInvoice(event.target.checked);
  };

  const handleChangeGaonly = (event) => {
    setGaonly(event.target.checked);
  };

  const handleChangeCancel = (event) => {
    setCancel(event.target.checked);
  };

  const handleChangeStartDate = (event) => {
    setStartDate(event.target.value);
  };

  const handleChangeEndDate = (event) => {
    setEndDate(event.target.value);
  };

  const handleChangeDeadline = (event) => {
    setDeadline(event.target.value);
  };

  const handleRowSelection = (event) => {
    setSelection(event);
  };

  function init(){
    (async () => {
      const result = await Post("getAllRaffle", "release");
      let option = [];
      for (var [key, value] of Object.entries(result)) {
        option.push({
          label: [value["SKU"], value["TITLE"], value["VENDOR"]].join(" / "),
          value: value["UUID"],
        });
      }
      setOptions(option);
    })();
    setInputValue("");
    setProductId("");
    setStatus("");
    setTitle("");
    setSku("");
    setVendor("");
    setSrc("");

    setInvoice(true);
    setGaonly(true);
    setStartDate("");
    setEndDate("");
    setDeadline("");
    setRowList([]);
    setEntries([]);
    setEntryStatus(false);
    setLabel("UPDATE RAFFLE");
  }

  async function getRaffleDetail(value) {
    try {
      setOpen(!open);
      const result = await Post("getRaffleDetail", "release", {
        uuid: value,
      });

      setProductId(result["PRODUCT_ID"]);
      setStatus(result["STATUS"]);
      setTitle(result["TITLE"]);
      setSku(result["SKU"]);
      setVendor(result["VENDOR"]);
      setSrc(result["product"]["imgsrc"]);

      setInvoice(result["INVOICE"] == 1 ? true : false);
      setGaonly(result["LOCAL"] == 1 ? true : false);
      setStartDate(result["START_ENTRY"]);
      setEndDate(result["DRAW_ENTRY"]);
      setDeadline(result["PURCHASE_DEADLINE"]);
      setRowList(result["product"]["stock"]);

      setButtonLabel(result["STATUS"], result["INVOICE"], setLabel);

      if (
        result["STATUS"] == "Open for Entries" ||
        result["STATUS"] == "Picking Winners" ||
        result["STATUS"] == "Raffle Closed"
      ) {
        setEntries(result["entries"]);
        setEntryStatus(true);
        if (result["STATUS"] == "Picking Winners") {
          setWinners(true);
          setSelection(await getSuggest(result["entries"]));
        } else {
          setWinners(false);
        }
      } else {
        setEntries([]);
        setEntryStatus(false);
        setWinners(false);
      }
    } catch (err) {
      alert("Can not find the product.\n" + err);
    } finally {
      setOpen(false);
    }
  }

  async function updateRaffle() {
    if (productId && startDate && endDate) {
      const info = {
        productId: productId,
        status: status,
        raffleid: value,
        startentry: startDate,
        endentry: cancel ? getNowYMDhmStr() : endDate,
        invoice: invoice ? "1" : "0",
        local: gaonly ? "1" : "0",
        deadline: deadline,
        select: select,
      };

      try {
        setOpen(!open);
        await Post(getEvent(status), "release", info);
        alert("Done!\nRaffle is updated.");
      } catch (err) {
        alert("Something error was happend.\n" + err);
      } finally {
        setOpen(false);
      }
    } else {
      alert("Please fill required field.");
    }
    await init();
  }

  return (
    <div>
      <div style={divStyle}>
        <Box
          component="div"
          sx={{
            whiteSpace: "nowrap",
            my: 2,
            bgcolor: "background.paper",
          }}
        ></Box>

        <SplitPane split="vertical" minSize={50} defaultSize="100%">
          <SplitPane split="horizontal" minSize={650} maxSize={650}>
            <SplitPane split="vertical" minSize={50} defaultSize="40%">
              <div>
                <Box component="div" sx={{ whiteSpace: "nowrap", my: 2 }} />
                <Box
                  component="div"
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    bgcolor: "background.paper",
                  }}
                >
                  <Autocomplete
                    value={inputValue}
                    onChange={(event, newValue) => {
                      setValue(newValue ? newValue["value"] : "");
                      if (newValue) {
                        getRaffleDetail(newValue["value"]);
                      }
                    }}
                    inputValue={inputValue}
                    onInputChange={(event, newInputValue) => {
                      setInputValue(newInputValue);
                    }}
                    id="controllable-states"
                    options={options}
                    sx={{ width: 400 }}
                    renderInput={(params) => (
                      <TextField {...params} label="ProductInfo" />
                    )}
                  />
                </Box>
                <Box component="div" sx={{ whiteSpace: "nowrap", my: 2 }} />
                <TextField
                  id="standard-read-only-input"
                  label="Status"
                  value={status}
                  InputProps={{ readOnly: true }}
                  variant="standard"
                />
                <Box component="div" sx={{ whiteSpace: "nowrap", my: 2 }} />
                <TextField
                  id="standard-read-only-input"
                  label="Title"
                  value={title}
                  InputProps={{ readOnly: true }}
                  variant="standard"
                />
                <Box component="div" sx={{ whiteSpace: "nowrap", my: 2 }} />
                <TextField
                  id="standard-read-only-input"
                  label="SKU"
                  value={sku}
                  InputProps={{ readOnly: true }}
                  variant="standard"
                />
                <Box component="div" sx={{ whiteSpace: "nowrap", my: 2 }} />
                <TextField
                  id="standard-read-only-input"
                  label="Vendor"
                  value={vendor}
                  InputProps={{ readOnly: true }}
                  variant="standard"
                />
                <Box component="div" sx={{ whiteSpace: "nowrap", my: 2 }} />
                <img src={src} width="50%" height="auto" loading="lazy" />
              </div>
              <div>
                <SplitPane split="vertical" minSize={50} defaultSize="40%">
                  <div>
                    <Box component="div" sx={{ whiteSpace: "nowrap", my: 2 }} />
                    <Stack spacing={3}>
                      <TextField
                        id="draw-start"
                        label="Draw start-time"
                        type="datetime-local"
                        sx={{ width: 250 }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        value={startDate}
                        disabled={setDisableAfterOpening(status)}
                        onChange={handleChangeStartDate}
                      />
                      <TextField
                        id="draw-end"
                        label="Draw end-time"
                        type="datetime-local"
                        sx={{ width: 250 }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        value={endDate}
                        disabled={setDisableAfterDrawClose(status)}
                        onChange={handleChangeEndDate}
                      />
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={gaonly}
                              onChange={handleChangeGaonly}
                              disabled={setDisableAfterDrawClose(status)}
                            />
                          }
                          label="GA ONLY"
                        />
                        <FormControlLabel
                          control={
                            <Switch
                              checked={invoice}
                              onChange={handleChangeInvoice}
                              disabled={setDisableAfterRaffleClose(status)}
                            />
                          }
                          label="Send Invoice"
                        />
                      </FormGroup>
                      <TextField
                        id="datetime-local"
                        label="Purchase deadline"
                        type="datetime-local"
                        sx={{ width: 250 }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        disabled={!invoice}
                        value={deadline}
                        onChange={handleChangeDeadline}
                      />
                    </Stack>
                  </div>
                  <div>
                    <div style={{ height: 500, width: "80%" }}>
                      <DataGrid
                        className={classes.root}
                        disableColumnFilter
                        disableSelectionOnClick
                        rows={rowList}
                        density="compact"
                        components={{ Toolbar: productToolbar }}
                        columns={productColumn}
                        pageSize={100}
                      />
                    </div>{" "}
                  </div>
                </SplitPane>
              </div>
            </SplitPane>
            <div />
          </SplitPane>
          <div />
        </SplitPane>

        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={open}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
      <div>
        {entryStatus
          ? entryGrid(classes, entries, winners, select, handleRowSelection, status)
          : null}
        {setCancelCheckBox(status) ? (
          <FormControlLabel
            control={
              <Checkbox checked={cancel} onChange={handleChangeCancel} />
            }
            label="Do you want to close this raffle immediately? "
          />
        ) : null}
        <Box component="div" sx={{ whiteSpace: "nowrap", my: 1 }} />
        <Button
          variant="contained"
          onClick={() => {
            updateRaffle();
          }}
        >
          {label}
        </Button>
        <Box component="div" sx={{ whiteSpace: "nowrap", my: 2 }} />
      </div>{" "}
    </div>
  );
}

async function getSuggest(arg) {
  let list = [];

  for (let elem of arg) {
    if (elem["SELECTED"] == true) {
      list.push(elem["id"]);
    }
  }

  return list;
}

const productColumn = [
  {
    field: "id",
    headerName: "Size",
    width: 150,
    sortable: false,
    align: "center",
  },
  {
    field: "stock",
    headerName: "In Stock",
    width: 150,
    sortable: false,
    align: "center",
  },
];

function entryGrid(
  classes,
  rowList,
  check,
  selectionModel,
  handleRowSelection,
  status
) {
  return (
    <div style={{ height: 500, width: "100%" }}>
      <DataGrid
        className={classes.root}
        disableSelectionOnClick
        rows={rowList}
        density="compact"
        checkboxSelection={check}
        selectionModel={selectionModel}
        onSelectionModelChange={handleRowSelection}
        components={{ Toolbar: entryToolbar }}
        columns={entryColumn(status)}
        pageSize={100}
      />
    </div>
  );
}

const entryColumn = (status) => {
  let column = [
    {
      field: "NAME",
      headerName: "Name",
      width: 200,
      sortable: false,
      align: "center",
    },
    {
      field: "SIZE",
      headerName: "Size",
      width: 100,
      sortable: false,
      align: "center",
    },
    {
      field: "ADDRESS",
      headerName: "ADDRESS",
      width: 500,
      sortable: false,
      align: "center",
    },
    {
      field: "PROVINCE_CODE",
      headerName: "PROVINCE",
      width: 150,
      sortable: false,
      align: "center",
    },
    {
      field: "id",
      headerName: "EMAIL",
      width: 300,
      sortable: false,
      align: "center",
    },
    {
      field: "PHONE",
      headerName: "PHONE",
      width: 150,
      sortable: false,
      align: "center",
    },
    {
      field: "CREATE_TIME",
      headerName: "ENTRY TIME",
      width: 300,
      sortable: false,
      align: "center",
    },
  ];

  if(status == "Raffle Closed"){
    column = [
    {
      field: "SELECTED",
      headerName: "",
      type: 'boolean', 
      width: 50,
      sortable: false,
      align: "center",},
     ...column]
  }

  return column;
};

function getNowYMDhmStr() {
  const date = new Date();
  const Y = date.getFullYear();
  const M = ("00" + (date.getMonth() + 1)).slice(-2);
  const D = ("00" + date.getDate()).slice(-2);
  const h = ("00" + date.getHours()).slice(-2);
  const m = ("00" + date.getMinutes()).slice(-2);

  return Y + "-" + M + "-" + D + "T" + h + ":" + m;
}

function setButtonLabel(status, invoice, setLabel) {
  switch (status) {
    case "Picking Winners":
      invoice == 1 ? setLabel("SEND INVOICE") : setLabel("SUBMIT WINNERS");
      break;
    case "Raffle Closed":
      setLabel("ARCHIVE RAFFLE");
      break;
    default:
      setLabel("UPDATE RAFFLE");
      break;
  }
}

function setCancelCheckBox(status) {
  switch (status) {
    case "Open for Entries":
      return true;
    default:
      return false;
  }
}

function setDisableAfterDrawClose(status) {
  switch (status) {
    case "Picking Winners":
      return true;
    case "Raffle Closed":
      return true;
    default:
      return false;
  }
}

function setDisableAfterOpening(status) {
  switch (status) {
    case "Open for Entries":
      return true;
    case "Picking Winners":
      return true;
    case "Raffle Closed":
      return true;
    default:
      return false;
  }
}

function setDisableAfterRaffleClose(status) {
  switch (status) {
    case "Raffle Closed":
      return true;
    default:
      return false;
  }
}

function getEvent(status) {
  switch (status) {
    case "Waiting for Open":
      return "updateRaffleDetail";
    case "Open for Entries":
      return "updateRaffleDetail";
    case "Picking Winners":
      return "submitRaffle";
    case "Raffle Closed":
      return "archiveRaffle";
    default:
      break;
  }
}
