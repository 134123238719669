import * as React from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import SplitPane from "react-split-pane";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import Button from "@mui/material/Button";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid";
import { createTheme } from "@mui/material/styles";
import { createStyles, makeStyles } from "@mui/styles";

import { Post } from "./restPost";

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}

const defaultTheme = createTheme();
const useStyles = makeStyles(
  (theme) =>
    createStyles({
      root: {
        border: 0,
        color:
          theme.palette.mode === "light"
            ? "rgba(0,0,0,.85)"
            : "rgba(255,255,255,0.85)",
        fontFamily: [
          "-apple-system",
          "BlinkMacSystemFont",
          '"Segoe UI"',
          "Roboto",
          '"Helvetica Neue"',
          "Arial",
          "sans-serif",
          '"Apple Color Emoji"',
          '"Segoe UI Emoji"',
          '"Segoe UI Symbol"',
        ].join(","),
        WebkitFontSmoothing: "auto",
        letterSpacing: "normal",
        "& .MuiDataGrid-columnsContainer": {
          backgroundColor:
            theme.palette.mode === "light" ? "#539ee9" : "#1d1d1d",
        },
        "& .MuiDataGrid-iconSeparator": {
          display: "none",
        },
        "& .MuiDataGrid-columnHeader, .MuiDataGrid-cell": {
          borderTop: `1px solid ${
            theme.palette.mode === "light" ? "#f0f0f0" : "#303030"
          }`,
          borderRight: `1px solid ${
            theme.palette.mode === "light" ? "#f0f0f0" : "#303030"
          }`,
        },
        "& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell": {
          borderBottom: `1px solid ${
            theme.palette.mode === "light" ? "#f0f0f0" : "#303030"
          }`,
        },
        "& .MuiDataGrid-cell": {
          color:
            theme.palette.mode === "light"
              ? "rgba(0,0,0,.85)"
              : "rgba(255,255,255,0.65)",
        },
        "& .MuiPaginationItem-root": {
          borderRadius: 0,
        },
      },
    }),
  { defaultTheme }
);

export default function RaffleCreate() {
  const classes = useStyles();
  const [productId, setProductId] = React.useState("");
  const [title, setTitle] = React.useState("");
  const [sku, setSku] = React.useState("");
  const [vendor, setVendor] = React.useState("");
  const [src, setSrc] = React.useState("");
  const [rowList, setRowList] = React.useState([]);
  const [invoice, setInvoice] = React.useState(true);
  const [gaonly, setGaonly] = React.useState(true);
  const [startDate, setStartDate] = React.useState(null);
  const [endDate, setEndDate] = React.useState(null);
  const [deadline, setDeadline] = React.useState(null);
  const [open, setOpen] = React.useState(false);

  const handleChangeInvoice = (event) => {
    setInvoice(event.target.checked);
  };

  const handleChangeGaonly = (event) => {
    setGaonly(event.target.checked);
  };

  const handleChangeStartDate = (event) => {
    setStartDate(event.target.value);
  };

  const handleChangeEndDate = (event) => {
    setEndDate(event.target.value);
  };

  const handleChangeDeadline = (event) => {
    setDeadline(event.target.value);
  };

  async function getProductInfo(value) {
    try {
      setOpen(!open);
      const result = await Post("getProductInfo", "release", {
        productId: value.trim(),
      });
      setProductId(value.trim());
      setTitle(result["product"]["title"]);
      setSku(result["product"]["sku"]);
      setVendor(result["product"]["vendor"]);
      setSrc(result["product"]["imgsrc"]);
      setRowList(result["product"]["stock"]);
    } catch (err) {
      alert("Can not find the product.\n" + err);
    } finally {
      setOpen(false);
    }
  }

  async function createRaffle() {
    if (productId && endDate) {
      const info = {
        productId: productId,
        title: title,
        sku: sku,
        vendor: vendor,
        startentry: startDate,
        endentry: endDate,
        invoice: invoice ? "1" : "0",
        local: gaonly ? "1" : "0",
        deadline: deadline,
      };

      try {
        setOpen(!open);
        const result = await Post("createRaffle", "release", info);
        alert("Done!\nRaffle is created.");
      } catch (err) {
        if(err["message"].slice(-3) == 500){
          alert("WARNING : The product exists in Raffle.\nPlease do [CREATE RAFFLE] after archiving.");
        }else{
          alert("Something error was happend.\n" + err);
        }
      } finally {
        setOpen(false);
      }
    } else {
      alert("Please fill required field.");
    }
  }

  return (
    <div>
      <Box
        component="div"
        sx={{
          whiteSpace: "nowrap",
          my: 2,
          bgcolor: "background.paper",
        }}
      ></Box>

      <SplitPane split="vertical" minSize={50} defaultSize="100%">
        <SplitPane split="horizontal" minSize={600}>
          <SplitPane split="vertical" minSize={50} defaultSize="40%">
            <div>
              <Box component="div" sx={{ whiteSpace: "nowrap", my: 2 }} />
              <TextField
                required
                id="outlined-required"
                label="ProductID"
                defaultValue=" "
                onBlur={(event) => getProductInfo(event.target.value)}
              />
              <Box component="div" sx={{ whiteSpace: "nowrap", my: 2 }} />
              <TextField
                id="standard-read-only-input"
                label="Title"
                value={title}
                InputProps={{ readOnly: true }}
                variant="standard"
              />
              <Box component="div" sx={{ whiteSpace: "nowrap", my: 2 }} />
              <TextField
                id="standard-read-only-input"
                label="SKU"
                value={sku}
                InputProps={{ readOnly: true }}
                variant="standard"
              />
              <Box component="div" sx={{ whiteSpace: "nowrap", my: 2 }} />
              <TextField
                id="standard-read-only-input"
                label="Vendor"
                value={vendor}
                InputProps={{ readOnly: true }}
                variant="standard"
              />
              <Box component="div" sx={{ whiteSpace: "nowrap", my: 2 }} />
              <img src={src} width="50%" height="auto" loading="lazy" />
            </div>

            <div>
              <SplitPane split="vertical" minSize={50} defaultSize="40%">
                <div>
                  <Box component="div" sx={{ whiteSpace: "nowrap", my: 2 }} />
                  <Stack spacing={3}>
                    <TextField
                      id="datetime-local"
                      label="Draw start-time"
                      type="datetime-local"
                      sx={{ width: 250 }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={handleChangeStartDate}
                    />
                    <TextField
                      id="datetime-local"
                      label="Draw end-time"
                      type="datetime-local"
                      sx={{ width: 250 }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={handleChangeEndDate}
                    />
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={gaonly}
                            onChange={handleChangeGaonly}
                          />
                        }
                        label="GA ONLY"
                      />
                      <FormControlLabel
                        control={
                          <Switch
                            checked={invoice}
                            onChange={handleChangeInvoice}
                          />
                        }
                        label="Send Invoice"
                      />
                    </FormGroup>
                    <TextField
                      id="datetime-local"
                      label="Purchase deadline"
                      type="datetime-local"
                      sx={{ width: 250 }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      disabled={!invoice}
                      onChange={handleChangeDeadline}
                    />
                  </Stack>
                </div>
                <div>
                  <div style={{ height: 500, width: "80%" }}>
                    <DataGrid
                      className={classes.root}
                      disableColumnFilter
                      disableSelectionOnClick
                      rows={rowList}
                      density="compact"
                      components={{ Toolbar: CustomToolbar }}
                      columns={column}
                      pageSize={100}
                    />
                  </div>{" "}
                </div>
              </SplitPane>
            </div>
          </SplitPane>
          <div>
            <Box component="div" sx={{ whiteSpace: "nowrap", my: 1 }} />
            <Button
              variant="contained"
              onClick={() => {
                createRaffle();
              }}
            >
              CREATE RAFFLE
            </Button>
          </div>
        </SplitPane>
        <div />
      </SplitPane>

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
}

const column = [
  {
    field: "id",
    headerName: "Size",
    width: 150,
    sortable: false,
    align: "center",
  },
  {
    field: "stock",
    headerName: "In Stock",
    width: 150,
    sortable: false,
    align: "center",
  },
];
